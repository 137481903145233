export function usePipedrive() {
    
    const config = useRuntimeConfig();
    const key = encodeURIComponent(`${config.public.pipedrive}`)
    const router = useRouter()
    const gtm = useGtm()

    async function searchPersons(data:any) {

        console.log('Search Person')

        const term = encodeURIComponent(data.howShouldWeGetInTouch.emailAddress)

        const url = `https://epcimprovements.pipedrive.com/v1/persons/search?term=${term}&api_token=${key}`

        const response = await fetch(url, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            }
        })

        const output = await response.json()

        if(output && output.data && output.data.items && output.data.items[0] && output.data.items[0].item) {
            
            await updatePerson(data, output.data.items[0].item.id)

        } else {

            await addPerson(data)

        }

    }

    /* Add Person */
    async function addPerson(data:any) {

        console.log('Add Person')

        let body = {
            name: data.howShouldWeGetInTouch.fullName,
            owner_id: 13188039,
            phone:  data.howShouldWeGetInTouch.contactNumber,
            email: {
                value: data.howShouldWeGetInTouch.emailAddress,
            },
            postal_address: data.findYourProperty.address,
            marketing_status: 'no_consent'
        }

        if(data.howShouldWeGetInTouch.emailMarketing) {
            body = {...body, marketing_status: 'subscribed'}
        }

        const url = `https://epcimprovements.pipedrive.com/v1/persons?api_token=${key}`

        const response = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })

        const output = await response.json()

        await addLead(data, output.data.id)

    }

    /* Update Person */
    async function updatePerson(data:any, value:string) {

        console.log('Update Person')

        const id = encodeURIComponent(value)

        let body = {
            name: data.howShouldWeGetInTouch.fullName,
            owner_id: 13188039,
            phone:  data.howShouldWeGetInTouch.contactNumber,
            email: {
                value: data.howShouldWeGetInTouch.emailAddress,
            },
            postal_address: data.findYourProperty.address,
            marketing_status: 'no_consent'
        }

        if(data.howShouldWeGetInTouch.emailMarketing) {
            body = {...body, marketing_status: 'subscribed'}
        }

        const url = `https://epcimprovements.pipedrive.com/v1/persons/${id}?api_token=${key}`

        const response = await fetch(url, {
            method: 'PUT',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })

        const output = await response.json()

        await addLead(data, output.data.id)

    }

    /* Add Lead */
    async function addLead(data:any, value:string) {

        console.log('Add Lead')

        const utm_source = useCookie('utm_source')
        const utm_medium = useCookie('utm_medium')
        const utm_campaign = useCookie('utm_campaign')
        const utm_term = useCookie('utm_term')
        const utm_content = useCookie('utm_content')

        const id = encodeURIComponent(value)

        let body = {

            title: `${data.howShouldWeGetInTouch.fullName} - ${data.findYourProperty.postcode}`,
            owner_id: 13188039,
            person_id: Number(id),

            /* Ownership Status */
            '63e4abac2a2c9ddbea2e4caea1fdbb991d3fcebc': Number(data.doYouOwnYourHome.option),

            /* Solar Bundle */
            '3afcfb58de4393de17abaf123c1837756a41281d': Number(data.whichSolarBundleAreYouIntrestedIn.option),

            /* Time Frame */
            '8b2d9010226af6a7a4e2dbaee8730e25fc336020': Number(data.whatIsYourIdealInstallationTimeframe.option),

            /* Communication Preference */
            '1dc0121ca155fa48148b4d443b6c40b6114798aa': data.howShouldWeGetInTouch.options.toString(),

            /* Marketing Status */
            '054440eabe0b47a32db0cba05e18e830d8636c06': Number(data.howShouldWeGetInTouch.emailMarketing ? 1826 : 1827),

            /* UTM */
            "f34ab158ca1e8d13745e00446a05666d6f6a4652": utm_source.value,
            "0429ac3f586a31c4eb2083a4cb45441705fc6db4": utm_medium.value,
            "c3e92a0ba10be7bb1064c8a38215da0b627d710b": utm_campaign.value,
            "289e74a258cb84b8094a05d79062202162f5bef1": utm_term.value,
            "236a4fa958730900912b1fd6ae0906720c35ee1a": utm_content.value

            /* Customer Lead Source */
            // '4d31aeab3647ae26d088f3d55d884cd3525da8d7': 1607, //Landing Page - Solar

        }
        
        const url = `https://epcimprovements.pipedrive.com/v1/leads?api_token=${key}`

        const response = await fetch(url, {
            method: 'post',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })

        const output = await response.json()

        if(data.extraInformation.value) {
            await addNotes(data, output.data.id)
        } else {

            gtm?.trackEvent({
                event: 'formSubmission',
                label: `Get a Quote - Solar - Form Submission`,
            })

            router.push({ path: "/get-a-quote/solar/thank-you" })

        }

    }

    /* Add Notes (Optinal) */
    async function addNotes(data:any, value:string) {

        console.log('Add Notes')

        const id = encodeURIComponent(value)

        let body = {
            lead_id: id,
            content: data.extraInformation.value
        }

        const url = `https://epcimprovements.pipedrive.com/v1/notes?api_token=${key}`

        const response = await fetch(url, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        })

        const output = await response.json()

        gtm?.trackEvent({
            event: 'formSubmission',
            label: `Get a Quote - Solar - Form Submission`,
        })

        router.push({ path: "/get-a-quote/solar/thank-you" })

    }

    /* Subscribe */
    async function subscribe(name:string, email:string){

        const term = encodeURIComponent(email)

        const url = `https://epcimprovements.pipedrive.com/v1/persons/search?term=${term}&api_token=${key}`

        const response = await fetch(url, {
            method: 'GET',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json'
            }
        })

        let output = await response.json()

        if(output && output.data && output.data.items && output.data.items[0] && output.data.items[0].item) {
            
            const id = encodeURIComponent(output.data.items[0].item.id)
    
            let body = {
                name: name,
                owner_id: 13188039,
                email: {
                    value: email,
                },
                marketing_status: 'subscribed'
            }
  
            const url = `https://epcimprovements.pipedrive.com/v1/persons/${id}?api_token=${key}`
    
            const response = await fetch(url, {
                method: 'PUT',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            })
    
            output = await response.json()

            return output

        } else {

            let body = {
                name: name,
                owner_id: 13188039,
                email: {
                    value: email,
                },
                marketing_status: 'subscribed'
            }

            const url = `https://epcimprovements.pipedrive.com/v1/persons?api_token=${key}`
    
            const response = await fetch(url, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(body)
            })
    
            output = await response.json()
            
            return output
        
        }

    }

    return {
        searchPersons,
        addPerson,
        updatePerson,
        addLead,
        subscribe
    }

}